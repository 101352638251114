import React from 'react';

// Dairiten
const DairitenEntry = React.lazy(() => import('./App/pages/dairiten/Entry'));
const DairitenConfirmation = React.lazy(() => import('./App/pages/dairiten/Confirm'));
const DairitenSuccess = React.lazy(() => import('./App/pages/dairiten/Success'));

// LP
const LPCustomerInfo = React.lazy(() => import('./App/pages/lp/CustomerInfo'));
const LPPaymentOption = React.lazy(() => import('./App/pages/lp/PaymentOption'));
const LPPayment = React.lazy(() => import('./App/pages/lp/Payment'));
const LPConfirmation = React.lazy(() => import('./App/pages/lp/Confirm'));
const LPSuccess = React.lazy(() => import('./App/pages/lp/Success'));
const LPFinish = React.lazy(() => import('./App/pages/lp/Finish'));
const LPCustomerInfoConfirm = React.lazy(() => import('./App/pages/lp/CustomerInfoConfirm'));

// New form
const Entry = React.lazy(() => import('./App/pages/new_form/CustomerInfo'));
const Confirmation = React.lazy(() => import('./App/pages/new_form/Confirmation'));
const Registered = React.lazy(() => import('./App/pages/new_form/Registered'));
const Success = React.lazy(() => import('./App/pages/new_form/Success'));
const Finish = React.lazy(() => import('./App/pages/new_form/Finish'));
const InvoiceCheck = React.lazy(() => import('./App/pages/new_form/InvoiceCheck'));
const Contract = React.lazy(() => import('./App/pages/new_form/Contract'));
const AddCreditCard = React.lazy(() => import('./App/pages/new_form/AddCreditCard'));
const AddCreditCardSuccess = React.lazy(() => import('./App/pages/new_form/AddCreditCardSuccess'));
const EmailExists = React.lazy(() => import('./App/pages/new_form/EmailExists'));
const CustomerInfoConfirm = React.lazy(() => import('./App/pages/new_form/CustomerInfoConfirm'));

// SW
const SwEntry = React.lazy(() => import('./App/pages/sw/Entry'));
const SwConfirmation = React.lazy(() => import('./App/pages/sw/Confirm'));
const SwSuccess = React.lazy(() => import('./App/pages/sw/Success'));

// Home
const Campaign = React.lazy(() => import('./App/pages/home/Campaign'));
const Present = React.lazy(() => import('./App/pages/home/Present'));

// Other
const NotFound = React.lazy(() => import('./App/pages/NotFound'));

const route = [
    // Dairiten
    { path: '/dairiten/:id', exact: true, name: '代理店向けの申込画面', component: DairitenEntry },
    { path: '/confirmation_dairiten/:id', exact: true, name: '代理店向けの情報確認画面', component: DairitenConfirmation },
    { path: '/success_dairiten/:id', exact: true, name: '代理店向けの登録完了画面', component: DairitenSuccess },

    // LP
    { path: '/lp_customer_info/:id', exact: true, name: 'ＬＰ専用の申込画面', component: LPCustomerInfo },
    { path: '/lp_payment_option', exact: true, name: 'ＬＰ専用の支払い方法選択画面', component: LPPaymentOption },
    { path: '/lp_payment', exact: true, name: 'ＬＰ専用の支払情報入力画面', component: LPPayment },
    { path: '/lp_confirmation', exact: true, name: 'ＬＰ専用の情報確認画面', component: LPConfirmation },
    { path: '/lp_success', exact: true, name: 'ＬＰ専用の完了画面', component: LPSuccess },
    { path: '/lp_finish', exact: true, name: 'ＬＰ専用の完了画面', component: LPFinish },
    { path: '/lp_customer_info_confirmation', exact: true, name: 'ＬＰ専用の基本情報確認画面', component: LPCustomerInfoConfirm },

    // New form
    { path: '/', exact: true, name: '申込区分指定画面', component: Entry },
    { path: '/entry', exact: true, name: '申込区分指定画面', component: Entry },
    { path: '/register_confirmation', exact: true, name: '申込情報確認画面', component: Confirmation },
    { path: '/registered', exact: true, name: '申込完了画面', component: Registered },
    { path: '/success', exact: true, name: '完了画面', component: Success },
    { path: '/finish', exact: true, name: '完了画面', component: Finish },
    { path: '/invoice_check', exact: true, name: '検針表確認画面', component: InvoiceCheck },
    { path: '/contract', exact: true, name: '電力情報画面', component: Contract },
    { path: '/add_credit_card', exact: true, name: '支払情報入力画面', component: AddCreditCard },
    { path: '/add_credit_card_success', exact: true, name: '完了画面', component: AddCreditCardSuccess },
    { path: '/email_exists', exact: true, name: 'メール確認画面', component: EmailExists },
    { path: '/customer_info_confirmation', exact: true, name: '基本情報確認画面', component: CustomerInfoConfirm },

    
    // Sw
    { path: '/handle_sw_error', exact: true, name: '申込情報再入力画面', component: SwEntry },
    { path: '/handle_sw_error_confirmation', exact: true, name: '申込情報再入力確認画面', component: SwConfirmation },
    { path: '/handle_sw_error_success', exact: true, name: '完了画面', component: SwSuccess },

    // Home
    { path: '/present', exact: true, name: 'ステッカー申し込み', component: Present },
    { path: '/campaign', exact: true, name: 'キャンペーン', component: Campaign },

    // Other
    { path: '/*', exact: false, name: '見つからないページ', component: NotFound },
];

export default route;
